import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/tobi/Sites/bochemschmidt/src/gatsby-templates/MdxWrapper.tsx";
export const metadata = {
  title: "Impressum"
};
export const _frontmatter = {};
const layoutProps = {
  metadata,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Impressum`}</h1>
    <p>{`Bochem.Schmidt Architekten Part GmbB`}<br parentName="p"></br>{`
`}{`Schwarzenbergstr. 12 – 14`}<br parentName="p"></br>{`
`}{`D - 66663 Merzig`}</p>
    <p>{`T +49 6861 . 91525 0`}<br parentName="p"></br>{`
`}{`F +49 6861 . 91525 50`}</p>
    <p><a parentName="p" {...{
        "href": "mailto:mail@bochemschmidt.de"
      }}>{`mail@bochemschmidt.de`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "bochemschmidt.de"
      }}>{`bochemschmidt.de`}</a></p>
    <p>{`Steuernummer 040/151/10886`}</p>
    <hr></hr>
    <h2>{`Sebastian Bochem`}</h2>
    <p>{`Mitgliedsnummer: F 1176`}<br parentName="p"></br>{`
`}{`Berufbezeichnung: Architekt (Verliehen in der Bundesrepublik Deutschland)`}</p>
    <h2>{`Ingo Schmidt`}</h2>
    <p>{`Mitgliedsnummer: F 1223`}<br parentName="p"></br>{`
`}{`Berufbezeichnung: Architekt (Verliehen in der Bundesrepublik Deutschland)`}</p>
    <hr></hr>
    <h2>{`Berufliche Regelungen:`}</h2>
    <p>{`Architektengesetz der Architektenkammer des Saarlandes`}</p>
    <hr></hr>
    <h2>{`Zuständige Kammer:`}</h2>
    <p>{`Architektenkammer des Saarlandes`}<br parentName="p"></br>{`
`}{`Neumarkt 11`}<br parentName="p"></br>{`
`}{`66117 Saarbrücken`}</p>
    <p>{`T +49 681 . 95441 0`}<br parentName="p"></br>{`
`}{`F +49 681 . 95441 11`}</p>
    <p><a parentName="p" {...{
        "href": "mailto:info@aksaarland.de"
      }}>{`info@aksaarland.de`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "www.aksaarland.de"
      }}>{`www.aksaarland.de`}</a></p>
    <hr></hr>
    <h2>{`Fotographie:`}</h2>
    <p>{`Mark K. Kraemer`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "http://www.markkkraemer.de/"
      }}>{`http://www.markkkraemer.de/`}</a></p>
    <hr></hr>
    <h2>{`Webseite:`}</h2>
    <p>{`Studio Schönig`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://stefanieschoenig.de"
      }}>{`https://stefanieschoenig.de`}</a></p>
    <p>{`Sascha Metz`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://smetz.dev"
      }}>{`https://smetz.dev`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      